<template>
  <div class="page successMessage">
    <van-image style="margin-top:3.8rem" width="3.73rem" height="2.67rem"
               src="https://oss.xiaoyi120.com/shop2.0/public/successTip.png"
    />
    <div class="tips_title">{{ info.title }}</div>
    <div class="tips_text">{{ info.text }}</div>
    <div class="tips_btns flex-r-sa-c">
      <div />
      <!-- <van-button round plain class="tips_btn" @click="$router.push('/home/index')">逛逛首页</van-button> -->
      <van-button round color="#0767AB" class="tips_btn" @click="reSub">重新提交</van-button>
      <div />
    </div>
  </div>
</template>

<script>
import './index.scss'
import { successMessage } from '@/utils/constants'
export default {
  name: 'Success',
  data() {
    return {
      info: {}
    }
  },
  created() { this.info = successMessage[this.$route.query.type] },
  methods: {
    reSub() {
      this.$router.push('/home/certification')
    }
  }
}
</script>

<style>
</style>
